import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["amount", "total"]

  connect() {
    this.computeTotal()
  }

  computeTotal() {
    this.totalTarget.value = this.amountTargets
        .filter(el => el.offsetParent !== null)
        .filter(el => el.value !== "")
        .map(el => parseFloat(el.value))
        .reduce((sum, b) => sum + b, 0)
  }
}
