import axios from 'axios'
import {Controller} from "stimulus"

export default class extends Controller {

  loadAndReplace(e) {
    e.preventDefault()
    let targetElement = document.querySelector(this.data.get("target"))
    targetElement.style.opacity = "0.5"
    axios.get(this.element.href)
        .then(function (response) {
          targetElement.style.opacity = "1"
          targetElement.innerHTML = response.data
          BSN.initCallback()
        })
  }

  loadAndReplaceSelf(e) {
    e.preventDefault()
    let targetElement = this.element
    targetElement.style.opacity = "0.5"
    axios.get(this.element.href)
        .then(function (response) {
          targetElement.style.opacity = "1"
          let fragment = document.createRange().createContextualFragment(response.data)
          fragment.childNodes.forEach((el) => {
            if (el.nodeType !== Node.TEXT_NODE) {
              targetElement.parentNode.insertBefore(el, targetElement)
            }
          })
          targetElement.remove()
          BSN.initCallback()
        })
  }
}
