import {Controller} from "stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = []

  saveSelectedTab(e) {
    let id = e.currentTarget.href.split("#").pop()
    Cookies.set(this.data.get("cookiePrefix") + "-memorized-tab", id)
  }
}
