import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["pastTo"]

  copy(event) {
    this.pastToTarget.value = event.params.value
    this.pastToTarget.classList.remove("highlighted")
    setTimeout(() => {
      this.pastToTarget.classList.add("highlighted")
    }, 0)
  }
}
