import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["stock", "initialStock", "deliveryTime", "linkedProduct"]

  connect() {
    this.products = JSON.parse(this.data.get("products"))
    this.stockTargets.forEach((el) => {
      el.disabled = true
    })
    this.computeAllTotals()
  }

  computeAllTotals() {
    let total = this.initialStock || 0.0
    let stock = this.delayedStock
    let soldUnits = this.soldUnits
    this.stockTargets.forEach((el) => {
      if (total === "") {
        total = ""
      } else {
        let key = [el.dataset.fiscalYear, el.dataset.month].join("-")
        total += (stock[key] || 0) - (soldUnits[key] || 0)
      }
      el.value = total
      el.classList.toggle("text-danger", total < 0)
    })
  }

  get soldUnits() {
    let soldUnits = {}
    this.products.forEach((product) => {
      product["forecasts"].forEach((forecast) => {
        let linkedProductQuantities = 0
        this.linkedProductTargets.forEach(el => {
          if (el.querySelector('[name$="[product_id]"]').value === product["id"].toString()) {
            let quantity = el.querySelector('[name$="[quantity]"]').value
            if (quantity) {
              linkedProductQuantities += parseFloat(quantity)
            }
          }
        })
        let key = [forecast["fiscal_year"], forecast["month"]].join("-")
        soldUnits[key] |= 0
        soldUnits[key] += forecast["sold_units"] * linkedProductQuantities
      })
    })
    return soldUnits
  }

  get delayedStock() {
    let stocks = {}
    this.stockTargets.forEach((el) => {
      let year = parseInt(el.dataset.fiscalYear)
      let month = parseInt(el.dataset.month)
      let date = new Date(el.dataset.date)
      let quantityCount = this.quantityFor(year, month)
      let key = this.delayedYearAndMonth(year, month, date).join("-")
      stocks[key] |= 0
      if (quantityCount !== undefined && stocks[key] !== undefined) {
        stocks[key] += quantityCount
      } else {
        stocks[key] = undefined
      }
    })
    return stocks
  }

  quantityFor(year, month) {
    let input = document.querySelector("[data-name='quantity'][data-fiscal-year='" + year + "'][data-month='" + month + "']")
    if (input.value !== "") {
      return parseFloat(input.value)
    }
  }

  get initialStock() {
    if (this.hasInitialStockTarget) {
      let value = this.initialStockTarget.value
      if (value !== "") {
        return parseFloat(value)
      }
    }
  }

  delayedYearAndMonth(year, month, date) {
    let newDate = new Date(date.getTime() + (this.deliveryTime || 0) * 24 * 60 * 60 * 1000)
    let newMonth = month + this.monthDiff(date, newDate)
    let newYear = year
    if (newMonth > 12) {
      newYear += 1
      newMonth -= 12
    }
    return [newYear, newMonth]
  }

  get deliveryTime() {
    if (this.hasDeliveryTimeTarget) {
      let value = this.deliveryTimeTarget.value
      if (value !== "") {
        return parseFloat(value)
      }
    }
  }

  monthDiff(dateFrom, dateTo) {
    return (dateTo.getMonth() - dateFrom.getMonth()) + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }
}
