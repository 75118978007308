import {Controller} from "stimulus"

const I18n = require('i18n-js')

export default class extends Controller {
  static targets = []

  connect() {
    this.fileLabelTarget.innerHTML = I18n.t("js.form.file_input.selected_file.zero")
  }

  displayFilename() {
    const count = this.element.files.length
    const fileNames = Array.from(this.element.files).map(x => x.name).join(", ")
    this.fileLabelTarget.innerHTML = I18n.t("js.form.file_input.selected_file", {
      count: count,
      files: fileNames
    })
  }

  get fileLabelTarget() {
    return this.element.closest(".custom-file").querySelector(".custom-file-label")
  }
}
