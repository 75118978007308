import {Controller} from "stimulus"
import FloatLabels from 'float-labels.js'

export default class extends Controller {
  connect() {
    this.floatingLabels = new FloatLabels(this.element, {
      style: 2
    })
  }

  rebuild() {
    this.floatingLabels.rebuild()
  }
}
