import {Controller} from "stimulus"

export default class extends Controller {
  initialize() {
    this.inputs = document.querySelectorAll(this.data.get("inputs"))
    let self = this
    this.element.value = Math.round(this.element.value)
    this.inputs.forEach((el) => {
      el.addEventListener("change", () => {
        self.computeTotal()
      })
    })
  }

  computeTotal() {
    let total = 0
    this.inputs.forEach((el) => {
      if (el.value !== "") {
        total += parseFloat(el.value)
      }
    })
    this.element.value = Math.round(total)
    this.element.dispatchEvent(new Event('change'))
  }
}
