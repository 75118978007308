import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["revealable", "input"]

  connect() {
    this.reveal()
    this.revealableTargets.forEach(function (el, _) {
      el.classList.add("toggle-content")
    })
  }

  reveal() {
    switch (this.inputTarget.nodeName) {
      case "SELECT":
        this.currentInput = this.inputTarget.options[this.inputTarget.selectedIndex].value
        break
      case "INPUT":
        switch (this.inputTarget.type) {
          case "radio":
            this.inputTargets.forEach((el) => {
              if (el.checked) {
                this.currentInput = el.value
              }
            })
            break
          case "checkbox":
            this.currentInput = this.inputTarget.checked ? this.inputTarget.id : ""
            break
        }
    }

    this.revealableTargets.forEach((el, _) => {
      let founded = el.dataset.revealableName.split(",").indexOf(this.currentInput) > -1
      if (founded) {
        this.showElement(el)
      } else {
        this.hideElement(el)
      }
    })
  }

  showElement(el) {
    // Get the natural height of the element
    let getHeight = function () {
      el.style.display = 'block'
      let height = el.scrollHeight + 'px'
      el.style.display = ''
      return height
    }

    let height = getHeight()
    el.classList.add('is-visible') // Make the element visible
    el.style.height = height

    // Once the transition is complete, remove the inline max-height so the content can scale responsively
    window.setTimeout(function () {
      el.style.height = ''
      el.style.overflow = 'visible'
    }, 250)
  }

  hideElement(el) {
    let self = this

    // Give the element a height to change from
    el.style.height = el.scrollHeight + 'px'
    // Set the height back to height = 0
    window.setTimeout(function () {
      el.style.height = '0'
      el.style.overflow = ''
    }, 1)

    // When the transition is complete, hide it
    window.setTimeout(function () {
      el.classList.remove('is-visible')
    }, 250)
  }

  get currentInput() {
    return this.data.get("current-input")
  }

  set currentInput(name) {
    this.data.set("current-input", name)
  }
}
