import {Popover} from 'bootstrap.native'
import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.popover = new Popover(this.element, {trigger: "focus"})
  }

  hide() {
    this.popover.hide() // prevent popover not correctly hiding when user changes focus quickly
  }
}
