import {Controller} from "stimulus"

export default class extends Controller {
  static targets = []

  disableLink(e) {
    this._disableElement(this.element)
  }

  disableDropdown() {
    const dropdownEl = this.element.closest(".dropdown")
    this._disableElement(dropdownEl)
  }

  _disableElement(el) {
    el.innerHTML = this.data.get("content")
    el.disabled = true
    el.removeAttribute('href')
    el.style.textDecoration = 'none'
    el.style.cursor = 'default'
    el.style.display = "initial"
  }
}
