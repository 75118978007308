import {Controller} from "stimulus"
import interact from "interactjs"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["resizer"]

  connect() {
    const sidebarElement = this.element
    const cookieName = this.element.id.toString() + "-memorized-width"
    let sideBarWidth = Cookies.get(cookieName) || this.element.clientWidth

    sidebarElement.style.flexBasis = sideBarWidth.toString() + "px"
    interact(this.resizerTarget).draggable({
      listeners: {
        move(event) {
          sideBarWidth -= event.dx
          sidebarElement.style.flexBasis = sideBarWidth.toString() + "px"
          Cookies.set(cookieName, sideBarWidth)
        },
        start(event) {
          event.currentTarget.classList.add("active")
        },
        end(event) {
          event.currentTarget.classList.remove("active")
        }
      },
      startAxis: 'x',
      lockAxis: 'x'
    })
  }
}
