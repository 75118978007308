document.addEventListener("trix-file-accept", function (event) {
  if (event.target.hasAttribute("no-uploads")) {
    event.preventDefault();
  }
});

document.addEventListener('trix-initialize', (e) => {
  if (e.target.hasAttribute("no-uploads")) {
    e.target.parentElement.querySelectorAll(".trix-button-group--file-tools").forEach((iconEl) => {
      iconEl.style.display = "none";
    });
  }
});

document.addEventListener("trix-initialize", (event) => {
  let fieldsetEl = event.target.closest("fieldset")
  if (fieldsetEl && fieldsetEl.disabled) {
    event.target.removeAttribute("contenteditable")
  }
})
