import axios from 'axios'
import {Controller} from "stimulus"

export default class extends Controller {

  submit(e) {
    let checkbox = e.currentTarget
    this.element.classList.toggle(this.data.get("toggleCssClass"))
    axios({
      method: "POST",
      url: checkbox.checked ? this.data.get("onUrl") : this.data.get("offUrl")
    })
  }
}
