// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap.native')
require('es6-promise/auto')
require('src/support/foreach')
require("trix")
require("@rails/actiontext")
require("classlist-polyfill")
require("url-search-params-polyfill")
require('src/i18n_js')

//
// StimulusJS
//
import "@stimulus/polyfills"
import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

//
// Turbolinks
//
var Turbolinks = require("turbolinks")
Turbolinks.start()
Turbolinks.setProgressBarDelay(100)

//
// ChartJS
//
import {Chart} from 'chart.js';

Chart.defaults.color = '#212529';
Chart.defaults.font.family = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial';


//
// Axios setup
//
import axios from 'axios'

const csrfToken = (document.querySelector("meta[name=csrf-token]") || {}).content;
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
axios.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';


//
// Import JS on src folder
//
import "../src/direct_uploads.js"
import "../src/trix_editor.js"
import "../src/turbolinks_scroll.js"
import "../src/fa.js"


document.addEventListener('turbolinks:load', () => {
  BSN.initCallback();

  // Open all trix-content external links in a new window
  document.querySelectorAll(".trix-content a").forEach((linkEl) => {
    linkEl.addEventListener("click", (e) => {
      if (!e.target.isContentEditable && e.target.host !== window.location.host) {
        e.target.setAttribute("target", "_blank")
      }
    }, true)
  })
})

//
// Import Google map loader (only once)
//

import {Loader} from "@googlemaps/js-api-loader";

window.gmapLoader = new Loader({
  apiKey: window.planr.googleMapApiKey,
  version: "weekly",
})
