import {Controller} from "stimulus"

export default class extends Controller {
  copyMonth(e) {
    e.preventDefault()
    const input = document.querySelector(this.element.dataset.currentTarget)
    document.querySelectorAll(this.element.dataset.target).forEach(function (el) {
      el.value = input.value
      el.dispatchEvent(new Event('change'))
    })
  }

  copyYear(event) {
    event.preventDefault()
    const inputs = document.querySelectorAll(`[data-name="${event.params.field}"][data-fiscal-year="${event.params.fiscalYear}"]`)
    inputs.forEach(function (el) {
      let otherInput = document.querySelector(`[data-name="${event.params.field}"][data-fiscal-year="${event.params.targetFiscalYear}"][data-month="${el.dataset.month}"]`)
      otherInput.value = el.value
      otherInput.dispatchEvent(new Event('change'))
    })
  }
}
