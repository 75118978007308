import tippy from 'tippy.js'
import {Controller} from "stimulus"

export default class extends Controller {
  initialize() {
    const title = this.element.getAttribute('title')
    this.element.removeAttribute('title')

    if (title) {
      tippy(this.element, {
        allowHTML: this.allowHtml,
        placement: this.element.dataset.placement || "top",
        content: title
      })
    }
  }

  get allowHtml() {
    return this.data.get("allowHtml") === "true"
  }
}
