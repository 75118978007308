import {Controller} from "stimulus"
import Rails from "@rails/ujs"
import {debounce} from "throttle-debounce"

export default class extends Controller {
  static targets = ["content", "form"]
  static values = {
    replaceHistory: {type: Boolean, default: true}
  }

  initialize() {
    this.search = debounce(300, this.search.bind(this))
  }

  connect() {
    this.formTarget.addEventListener("ajax:success", (e) => {
      clearTimeout(this.loader)
      this.contentTarget.innerHTML = e.detail[2].response
      BSN.initCallback(this.contentTarget)
      this.hideLoading()
    })
  }

  search() {
    clearTimeout(this.loader)
    this.loader = setTimeout(this.displayLoading.bind(this), 300)

    let formData = new FormData(this.formTarget)
    let search = new URLSearchParams(formData)

    if (this.replaceHistoryValue) {
      let queryString = search.toString()
      history.replaceState({turbolinks: {}}, "", "?" + queryString)
    }

    Rails.fire(this.formTarget, 'submit') // using `dispatchEvent` seems not working on firefox (form is submit without ajax request)
  }

  displayLoading() {
    this.contentTarget.style.opacity = "0.5"
    this.element.querySelector(".live-search-input .search").style.display = "none"
    this.element.querySelector(".live-search-input .loading").style.display = "block"
  }

  hideLoading() {
    this.contentTarget.style.opacity = "1"
    this.element.querySelector(".live-search-input .search").style.display = "block"
    this.element.querySelector(".live-search-input .loading").style.display = "none"
  }
}
