import {Controller} from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import axios from "axios"

export default class extends Controller {
  static values = {
    groupName: String
  }
  static targets = ["position"]

  connect() {
    new Sortable(this.element, {
      animation: 150,
      swapThreshold: 0.65,
      handle: '.sortable-position__draggable-handler',
      ghostClass: 'sortable-position__draggable-ghost',
      draggable: '.sortable-position__draggable-item',
      group: this.groupNameValue,
      onEnd: (e) => {
        let url = e.item.dataset.sortablePositionUrl
        if (url) {
          axios.put(url, {
            position: e.newIndex + 1,
            group_id: e.to.dataset.sortablePositionGroupIdValue
          })
        }

        let i = 1
        this.positionTargets.forEach((el) => {
          el.value = i++
        })
      }
    })
  }
}
