import {Controller} from "stimulus"

export default class extends Controller {

  displayAjaxResponse(event) {
    const xhr = event.detail[2]
    this._displayResponse(xhr.responseText, xhr)
  }

  displayAjaxError(event) {
    const xhr = event.detail[2]
    alert(`Error ${xhr.status} ${xhr.statusText}`)
  }

  _displayResponse(response, xhr) {
    if (xhr.getResponseHeader("X-bypass-remote-form") === "true") {
      return
    }

    const redirect = xhr.getResponseHeader("X-Xhr-Redirect")
    if (redirect) {
      window.location.reload() // fix bug with bootstrap modal (backdrop isn't present when re-opening a modal)
    } else {
      this.element.innerHTML = response
      BSN.initCallback()
    }
  }
}
