import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ["source", "filterable"]

  clear(e) {
    e.preventDefault()
    const activeElement = this.element.querySelector(".btn.active")
    if (activeElement)
      activeElement.classList.remove('active')
    this.element.querySelectorAll("input[checked]").forEach(el => {
      el.checked = false
    })
  }
}
