import {Controller} from "stimulus"
import {faMapMarkerAlt as fasMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'

export default class extends Controller {
  static values = {
    markers: Array
  }

  connect() {
    gmapLoader.load().then((google) => {
      this.gInfoWindow = new google.maps.InfoWindow // Create only ONE infoWindow in the global scope to prevent having multiple infoWindow open
      this.gMap = new google.maps.Map(this.element, {
        center: this.centerCoordinate,
        streetViewControl: false,
        mapTypeControl: false,
        zoom: 13,
        mapId: "a83a6233872e047"
      })

      this._placeMarkers()
      this._placeDistricts()
      this._autoFitMap()
    })
  }

  _placeMarkers() {
    this.markersValue.forEach(marker => {
      let options = {
        position: this._markerPosition(marker),
        title: marker.companyName,
        map: this.gMap,
        icon: {
          path: fasMapMarkerAlt.icon[4],
          fillColor: getComputedStyle(document.documentElement).getPropertyValue(`--${marker.sector.color}`),
          fillOpacity: 1,
          anchor: new google.maps.Point(
              fasMapMarkerAlt.icon[0] / 2, // width
              fasMapMarkerAlt.icon[1] // height
          ),
          scale: 0.06
        }
      }
      if (marker.label) {
        options["label"] = {
          text: marker.label,
          color: "white",
          fontWeight: "500"
        }
      }
      const gMarker = new google.maps.Marker(options)

      if (marker.content) {
        gMarker.addListener("click", () => {
          this.gInfoWindow.setContent(marker.content)
          this.gInfoWindow.open(this.gMap, gMarker)
        })
      }
    })
  }

  _placeDistricts() {
    const src = 'https://previsionnel.ch/VDG_QUARTIER_VILLE.kml?t=1638168456'
    const kmlLayer = new google.maps.KmlLayer(src, {
      suppressInfoWindows: true,
      preserveViewport: false,
      map: this.gMap
    })

    kmlLayer.addListener('click', (event) => {
      const div = document.createElement('div')
      const h5 = document.createElement('h5')

      h5.appendChild(document.createTextNode(event.featureData.name))
      div.appendChild(h5)

      const ul = document.createElement('ul')
      ul.style.maxHeight = '160px'
      ul.style.overflow = 'auto'
      this.markersValue
          .filter((marker) => marker.district === event.featureData.name)
          .forEach((marker) => {
            const li = document.createElement('li')
            li.appendChild(document.createTextNode(marker.companyName))
            ul.appendChild(li)
          })
      div.appendChild(ul)

      this.gInfoWindow.setContent(div)
      this.gInfoWindow.setPosition(event.latLng)
      this.gInfoWindow.open(this.gMap)
    })
  }

  _autoFitMap() {
    const bounds = new (google.maps.LatLngBounds)
    this.markersValue.forEach(marker => {
      bounds.extend(this._markerPosition(marker))
    })
    this.gMap.fitBounds(bounds)
  }

  _markerPosition(marker) {
    return {
      lat: marker.coordinates[0],
      lng: marker.coordinates[1]
    }
  }

  get centerCoordinate() {
    return this._markerPosition(this.markersValue[0])
  }
}
