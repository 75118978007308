import {Controller} from "stimulus"

const I18n = require('i18n-js')

export default class extends Controller {
  static targets = ["add", "subtract", "output"]
  static values = {amount: Number}

  connect() {
    this.computeTotal()
  }

  computeTotal() {
    let total = this.amountValue || 0
    this.addTargets.filter(el => el.value !== "").forEach(el => total += parseFloat(el.value))
    this.subtractTargets.filter(el => el.value !== "").forEach(el => total -= parseFloat(el.value))
    total = Math.round(total * 100) / 100

    switch (this.outputTarget.nodeName) {
      case "INPUT":
        this.outputTarget.value = total
        break
      default:
        this.outputTarget.innerHTML = I18n.toCurrency(total, {strip_insignificant_zeros: true})
    }
  }
}
