import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.parentElement = this.element.closest(".form-group")
    this.maxWordsCount = this.data.get("max-words") || 200
    this.appendWordsCounter()
    this.updateWordsCounter()
    this.countWords()
  }

  disconnect() {
    this.warningElement.remove()
  }

  appendWordsCounter() {
    this.warningElement = document.createElement("small")
    this.warningElement.classList.add("words-counter", "form-text", "text-muted", "text-right")
    this.parentElement.appendChild(this.warningElement)
    this.parentElement.classList.add("words-count")
  }

  updateWordsCounter() {
    this.warningElement.innerHTML = this.wordsCount.toString() + " / " + this.maxWordsCount.toString() + " mots"
  }

  countWords() {
    this.updateWordsCounter()
    this.parentElement.classList.toggle("max-reached", this.wordsCount > this.maxWordsCount)
  }

  get wordsCount() {
    let value = (this.element.tagName === "TRIX-EDITOR") ? this.element.editor.getDocument().toString() : this.element.value
    return value.trim().split(/\s/).filter(String).length
  }
}
