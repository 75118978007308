import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.element.style.display = "block";
    this.element.style.overflow = "hidden";
    this.element.style.resize = "none";
    this.element.style.transition = "none !important";
    this.element.style.minHeight = "38px";
    this.adjustHeight();
  }

  adjustHeight() {
    let scrollPosition = document.scrollingElement.scrollTop;
    this.element.style.height = "0";
    this.element.style.height = (this.element.scrollHeight + 2) + "px";
    document.scrollingElement.scrollTo(0, scrollPosition);
  }
}
