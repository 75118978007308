import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  connect() {
    let appendTo = "parent"
    let container = this.data.get("container")
    if (container) {
      appendTo = () => {
        return document.querySelector(container)
      }
    }

    tippy(this.element, {
      trigger: this.data.get("trigger") || "click",
      content: this.data.get("content"),
      placement: this.data.get("placement") || "top",
      allowHTML: true,
      interactive: true,
      theme: "light-border",
      appendTo: appendTo,
      maxWidth: 550
    })
  }
}
