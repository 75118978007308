var Turbolinks = require("turbolinks")

Turbolinks.scroll = {};

document.addEventListener("turbolinks:load", () => {

  const elements = document.querySelectorAll("[data-turbolinks-scroll]");

  elements.forEach(function (element) {
    element.addEventListener("ajax:success", (e) => {
      Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    });
    element.addEventListener("click", (e) => {
      Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    });
  });

  if (Turbolinks.scroll['top']) {
    document.scrollingElement.scrollTo(0, Turbolinks.scroll['top']);
  }

  Turbolinks.scroll = {};
});
