import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ["source", "filterable"]

  filter() {
    const lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()
    this.filterableTargets.forEach(el => {
      let filterableValue = el.dataset.filterValue.toLowerCase()
      el.classList.toggle("d-none", !filterableValue.includes(lowerCaseFilterTerm))
    })
  }
}
