import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['item']
  static classes = ['d-none']

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none'
  }

  toggle(e) {
    e.preventDefault()
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })
  }

  show(e) {
    e.preventDefault()
    this.itemTargets.forEach(item => {
      item.classList.remove(this.class)
    })
  }

  hide(e) {
    e.preventDefault()
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
  }
}
