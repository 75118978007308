import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["submit"]

  connect() {
    if (this.hasSubmitTarget) {
      this.submitTarget.style.transition = "opacity 0.25s ease-out"
      this.submitTarget.disabled = !this.element.id.startsWith("new_")
    }
    let self = this
    this.element.querySelectorAll("textarea,input,trix-editor").forEach((el) => {
      if (el.classList.contains("is-invalid")) {
        this.enabledSubmit()
      }
      el.addEventListener("input", self.enabledSubmit.bind(self))
    })
  }

  enabledSubmit() {
    if (this.submitTarget.disabled) {
      this.submitTarget.disabled = false
    }
  }
}
