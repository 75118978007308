import {Controller} from "stimulus"
import Chart from "chart.js/auto"
import "chartjs-adapter-date-fns"
import {fr} from "date-fns/locale"

export default class extends Controller {
  static targets = ["graph", "input"]
  static values = {
    dates: Array
  }

  connect() {
    this.draw()
  }

  draw() {
    this.chart = new Chart(this.graphTarget, {
          type: "line",
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                data: this.chartDataset,
                backgroundColor: "#6366F1",
                borderColor: "rgba(99, 102, 241, 0.7)",
                tension: 0.1,
              }
            ]
          },
          options: {
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true
              },
              x: {
                type: "time",
                adapters: {
                  date: {
                    locale: fr
                  }
                },
                time: {
                  unit: "month",
                  tooltipFormat: "MMMM yyyy",
                },
                ticks: {
                  display: false // hide x axis labels
                }
              }
            },
            plugins: {
              tooltip: {
                callbacks: {}
              },
              legend: {
                display: false
              }
            }
          }
        }
    )
  }

  increaseInputs(e) {
    e.preventDefault()
    this.inputTargets.map(input => input.value = parseInt(input.value) + (e.shiftKey ? 10 : 1))
    this.redraw()
  }

  decreaseInputs(e) {
    e.preventDefault()
    this.inputTargets.map(input => input.value = parseInt(input.value) - (e.shiftKey ? 10 : 1))
    this.redraw()
  }

  redraw() {
    this.chart.data.datasets.forEach(dataset => dataset.data = this.chartDataset)
    this.chart.update()
  }

  get chartLabels() {
    return this.datesValue.map(stringDate => {
      return new Date(stringDate)
    })
  }

  get chartDataset() {
    return this.datesValue.map(stringDate => {
      const input = this.inputTargets.find(input => parseInt(input.dataset.month) === new Date(stringDate).getMonth() + 1)
      return (input ? input.value : null)
    })
  }
}
