import {Controller} from "stimulus"

export default class extends Controller {
  static values = {
    className: {type: String, default: "text-danger"}
  }

  connect() {
    this.toggle()
  }

  toggle() {
    this.element.classList.toggle(this.classNameValue, (this.element.value || 0) < 0)
  }
}
