import Chart from 'chart.js/auto'
import {Controller} from "stimulus"
import 'chartjs-adapter-date-fns'
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'

const I18n = require('i18n-js')

export default class extends Controller {
  static values = {
    chartData: Object
  }
  static targets = [
    "graph",
    "turnoverRange", "turnoverRangeLabel",
    "fixedChargesRange", "fixedChargesRangeLabel",
    "variableChargesRange", "variableChargesRangeLabel"
  ]

  initialize() {
    this.chart = new Chart(this.graphTarget, {
      type: "line",
      data: {
        labels: this.chartDataValue.dates,
        datasets: [
          {
            id: "liquidityEnd",
            label: this.chartDataValue.liquidityEnd.label,
            data: this.chartDataValue.liquidityEnd.values,
            backgroundColor: "#44e13f",
            borderColor: "#44e13f",
            tension: 0.1,
            stack: "G1",
          },
          {
            id: "cashFlowBalance",
            label: this.chartDataValue.cashFlowBalance.label,
            data: this.chartDataValue.cashFlowBalance.values,
            backgroundColor: "#bee13f",
            borderColor: "#bee13f",
            tension: 0.1,
            stack: "G2",
          },
          {
            id: "totalRevenues",
            label: this.chartDataValue.totalRevenues.label,
            data: this.chartDataValue.totalRevenues.values,
            backgroundColor: "#6366F1",
            borderColor: "#6366F1",
            tension: 0.1,
            stack: "G3",
          },
          {
            id: "fixedCharges",
            label: this.chartDataValue.fixedCharges.label,
            data: this.chartDataValue.fixedCharges.values,
            backgroundColor: "rgba(225,94,63,0.50)",
            borderColor: "#E15E3F",
            tension: 0.1,
            fill: true,
            pointStyle: "line",
            stack: "G4",
          },
          {
            id: "variableCharges",
            label: this.chartDataValue.variableCharges.label,
            data: this.chartDataValue.variableCharges.values,
            backgroundColor: "rgba(225,160,63,0.20)",
            borderColor: "#e1a03f",
            tension: 0.1,
            fill: true,
            pointStyle: "line",
            stack: "G4",
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            beginAtZero: true,
            stacked: true
          },
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM yy'
              }
            },
            adapters: {
              date: {
                locale: fr
              }
            },
            grid: {
              lineWidth: function (scaleContext) {
                const date = new Date(scaleContext.tick.value)
                return date.getMonth() === 0 ? 2 : 1
              },
              color: function (scaleContext) {
                const date = new Date(scaleContext.tick.value)
                return date.getMonth() === 0 ? "#c0c2c9" : "#F2F3F7"
              }
            }
          }
        },
        plugins: {
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: this.externalTooltipHandler,
            callbacks: {
              title: (tooltipItem) => {
                const date = new Date(this.chartDataValue.dates[tooltipItem[0].dataIndex])
                return format(date, "MMMM yyyy", {locale: fr})
              },
              label: function (tooltipItem) {
                return [tooltipItem.dataset.label, I18n.toCurrency(tooltipItem.raw, {format: "%u %n", delimiter: " "})]
              }
            }
          }
        }
      }
    })
  }

  refreshGraph() {
    const turnoverMultipleFunction = (value) => {
      return value ? value * (this.turnoverRangeTarget.value / 100) : 0
    }
    const fixedChargesMultipleFunction = (value) => {
      return value ? value * (this.fixedChargesRangeTarget.value / 100) : 0
    }
    const variableChargesMultipleFunction = (value, i) => {
      if (!value) {
        return 0
      }
      const multiplier = (value / this.chartDataValue.totalRevenues.values[i]) * (this.variableChargesRangeTarget.value / 100.0)
      return this.chartDataValue.totalRevenues.values[i] * multiplier
    }
    const cashFlowBalanceMultipleFunction = (value, i) => {
      if (!value) {
        return 0
      }
      const variableCharges = this.chartDataValue.variableCharges.values[i]
      const fixedCharges = this.chartDataValue.fixedCharges.values[i]
      return turnoverMultipleFunction(value) - (fixedChargesMultipleFunction(fixedCharges) - fixedCharges) - (variableChargesMultipleFunction(variableCharges, i) - variableCharges)
    }

    this.chart.data.datasets.forEach(dataset => {
      const totalRevenuesValues = this.chartDataValue.totalRevenues.values.map(turnoverMultipleFunction)
      const fixedChargesValues = this.chartDataValue.fixedCharges.values.map(fixedChargesMultipleFunction)
      const variableChargesValues = this.chartDataValue.variableCharges.values.map(variableChargesMultipleFunction)
      const cashFlowBalanceValues = this.chartDataValue.cashFlowBalance.values.map(cashFlowBalanceMultipleFunction)

      if (dataset.id === "totalRevenues") {
        dataset.data = totalRevenuesValues
      }
      if (dataset.id === "fixedCharges") {
        dataset.data = fixedChargesValues
      }
      if (dataset.id === "variableCharges") {
        dataset.data = variableChargesValues
      }
      if (dataset.id === "cashFlowBalance") {
        dataset.data = cashFlowBalanceValues
      }
      if (dataset.id === "liquidityEnd") {
        let turnoverSum = 0
        let fixedChargesSum = 0
        let variableChargesSum = 0
        dataset.data = this.chartDataValue.liquidityEnd.values.map((value, i) => {
          turnoverSum += totalRevenuesValues[i] - this.chartDataValue.totalRevenues.values[i]
          fixedChargesSum += fixedChargesValues[i] - this.chartDataValue.fixedCharges.values[i]
          variableChargesSum += variableChargesValues[i] - this.chartDataValue.variableCharges.values[i]
          return value + turnoverSum - fixedChargesSum - variableChargesSum
        })
      }
    })

    this.turnoverRangeLabelTarget.innerText = `${this.turnoverRangeTarget.value}%`
    this.fixedChargesRangeLabelTarget.innerText = `${this.fixedChargesRangeTarget.value}%`
    this.variableChargesRangeLabelTarget.innerText = `${this.variableChargesRangeTarget.value}%`

    this.chart.update()
  }

  externalTooltipHandler = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context
    const tooltipEl = this.getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0
      return
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || []
      const bodyLines = tooltip.body.map(b => b.lines)

      const tableHead = document.createElement('thead')

      titleLines.forEach(title => {
        const tr = document.createElement('tr')
        const th = document.createElement('th')
        th.colSpan = bodyLines.length
        th.style.padding = '5px 10px'
        th.style.textAlign = 'center'
        th.appendChild(document.createTextNode(title))
        tr.appendChild(th)
        tableHead.appendChild(tr)
      })

      const tableBody = document.createElement('tbody')
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i]

        const tr = document.createElement('tr')
        tr.style.backgroundColor = 'inherit'

        const span = document.createElement('span')
        span.style.background = colors.backgroundColor
        span.style.borderColor = colors.borderColor
        span.style.borderWidth = '2px'
        span.style.height = '10px'
        span.style.width = '10px'
        span.style.display = 'inline-block'

        const td = document.createElement('td')
        td.style.width = '10px'
        td.style.padding = '0 0 0 10px'
        td.appendChild(span)
        tr.appendChild(td)

        body.forEach((label) => {
          const td = document.createElement('td')
          td.style.padding = '0 10px'
          td.style.whiteSpace = 'nowrap'
          td.style.fontSize = '14px'
          td.appendChild(document.createTextNode(label))
          tr.appendChild(td)
        })
        tableBody.appendChild(tr)
      })

      const tableRoot = tooltipEl.querySelector('table')

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove()
      }

      // Add new children
      tableRoot.appendChild(tableHead)
      tableRoot.appendChild(tableBody)
    }

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY + 'px'
    tooltipEl.style.font = tooltip.options.bodyFont.string
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
  }

  getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.75)'
      tooltipEl.style.borderRadius = '3px'
      tooltipEl.style.color = 'white'
      tooltipEl.style.opacity = 1
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.transform = 'translate(-50%, 0)'
      tooltipEl.style.transition = 'all .1s ease'

      const table = document.createElement('table')
      table.style.margin = '0'

      tooltipEl.appendChild(table)
      chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
  }
}
