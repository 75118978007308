import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["sectorSelect", "nogaSelect"]

  connect() {
    this.filterOptions()
  }

  filterOptions() {
    let selectedNogaCodes = this.sectorSelectTarget.options[this.sectorSelectTarget.selectedIndex].dataset.nogaCodes
    for (let option of this.nogaSelectTarget.options) {
      let founded = !selectedNogaCodes || option.value === "" || JSON.parse(selectedNogaCodes).indexOf(parseInt(option.dataset.nogaCode)) > -1
      option.disabled = !founded
    }
  }

  resetNogaSelect() {
    this.nogaSelectTarget.selectedIndex = null
    this.nogaSelectTarget.dispatchEvent(new Event('change'))
  }
}
