import {Controller} from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["label", "fileField"]

  openFileField(e) {
    e.preventDefault()
    this.fileFieldTarget.click()
  }

  submitForm() {
    this.labelTarget.innerHTML = this.element.dataset.loadingIcon
    Rails.fire(this.element, 'submit') // ensure submit form with rails-ujs to allow using ajax callbacks
  }
}
