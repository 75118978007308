import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["btnDisplayAll", "btnHideRight", "btnDisplayOnlyMiddle", "btnHideLeft"]

  connect() {
    this.leftSidebar = document.getElementById("left-sidebar")
    this.rightSidebar = document.getElementById("right-sidebar")
    this.toggleSidebar()
  }


  toggleSidebar() {
    this.btnDisplayAllTarget.classList.remove("active")
    this.btnHideRightTarget.classList.remove("active")
    this.btnDisplayOnlyMiddleTarget.classList.remove("active")
    this.btnHideLeftTarget.classList.remove("active")

    switch (localStorage.getItem("display-mode")) {
      case "leftAndMiddle":
        this.leftSidebar.classList.toggle('collapsed', false)
        if (this.rightSidebar) {
          this.rightSidebar.classList.toggle('collapsed', true)
        }
        this.btnHideRightTarget.classList.add("active")
        break

      case "middle":
        this.leftSidebar.classList.toggle('collapsed', true)
        if (this.rightSidebar) {
          this.rightSidebar.classList.toggle('collapsed', true)
        }
        this.btnDisplayOnlyMiddleTarget.classList.add("active")
        break

      case "middleAndRight":
        this.leftSidebar.classList.toggle('collapsed', true)
        if (this.rightSidebar) {
          this.rightSidebar.classList.toggle('collapsed', false)
        }
        this.btnHideLeftTarget.classList.add("active")
        break

      default: // all
        this.leftSidebar.classList.toggle('collapsed', false)
        if (this.rightSidebar) {
          this.rightSidebar.classList.toggle('collapsed', false)
        }
        this.btnDisplayAllTarget.classList.add("active")
    }
  }


  displayAll(e) {
    e.preventDefault()
    localStorage.setItem("display-mode", "all")
    this.toggleSidebar()
  }

  hideRight(e) {
    e.preventDefault()
    localStorage.setItem("display-mode", "leftAndMiddle")
    this.toggleSidebar()
  }

  displayOnlyMiddle(e) {
    e.preventDefault()
    localStorage.setItem("display-mode", "middle")
    this.toggleSidebar()
  }

  hideLeft(e) {
    e.preventDefault()
    localStorage.setItem("display-mode", "middleAndRight")
    this.toggleSidebar()
  }
}
