import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toMove"]
  connect() {
    this.toMoveTargets
      .reverse()
      .forEach(element => {
        const previousSiblingChildren = element.previousElementSibling.children[0]
        const nextSiblings = this.#getNextSiblings(element)
        // Move current item
        previousSiblingChildren.appendChild(element)
        // move all next siblings
        nextSiblings.forEach((sibling) => {
          previousSiblingChildren.appendChild(sibling)
        })
      })
  }

  #getNextSiblings(elem, filter) {
    let elements = [];
    while (elem = elem.nextElementSibling) {
      if (elem.nodeType === 3) continue; // text node
      if (!filter || filter(elem)) elements.push(elem);
    }
    return elements;
  }
}
