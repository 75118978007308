import {Controller} from "stimulus"

export default class extends Controller {
  static targets = []
  formChanged

  initialize() {
    window.addEventListener('beforeunload', (event) => {
      if (this.formChanged) {
        event.returnValue = 'You have attempted to leave this page. Are you sure you want to exit this page?'
      }
    })
  }

  enabledConfirmationMessage(e) {
    this.formChanged = true
  }

  disabledConfirmationMessage(e) {
    this.formChanged = false
  }
}
