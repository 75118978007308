import axios from 'axios'
import {Modal} from 'bootstrap.native'
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.cssClass = this.element.dataset.modalClass
  }

  show(e) {
    e.preventDefault()
    this.modal = document.querySelector("#dynamic-modal")
    this.bsnModal = new Modal(this.modal, {backdrop: "static"})

    let loadRemoteContent = () => {
      let self = this
      axios.get(this.remoteURL)
          .then(function (response) {
            const redirect = response.headers["x-xhr-redirect"]
            if (redirect) {
              self.bsnModal.hide();
              window.location = redirect
            } else {
              self.bsnModal.setContent(response.data)
              BSN.initCallback()
            }
          })
      this.modal.removeEventListener("shown.bs.modal", loadRemoteContent)
    }

    let resetModalCss = () => {
      this.modal.querySelector(".modal-dialog").classList.remove(this.cssClass)
      this.modal.removeEventListener("hidden.bs.modal", resetModalCss)
    }

    if (this.modal.classList.contains("show")) {
      loadRemoteContent()
      resetModalCss()
    } else {
      this.modal.addEventListener("shown.bs.modal", loadRemoteContent)
      this.modal.addEventListener("hidden.bs.modal", resetModalCss)
    }

    this.modal.querySelector(".modal-dialog").classList.add(this.cssClass)
    this.bsnModal.setContent("<div class='modal-body text-center'>" + this.loadingElement + "</div>")
    this.bsnModal.show()
  }

  get remoteURL() {
    let url = ""
    if (this.element.tagName === "FORM") {
      url = `${this.element.action}?${new URLSearchParams(new FormData(this.element)).toString()}`
    } else {
      url = this.element.href
    }
    return url
  }

  get loadingElement() {
    return "<div class='modal-body text-center'>" + this.modal.dataset.loaderIcon + "</div>"
  }

  get cssClass() {
    return this.data.get("css-class")
  }

  set cssClass(value) {
    this.data.set("css-class", value)
  }
}
