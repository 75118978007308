import axios from 'axios'
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["tab", "tabPanel"]
  static classes = ["activeTab"]

  show(e) {
    e.preventDefault()
    history.replaceState({turbolinks: {}}, "", e.currentTarget.href)

    const pageTitle = e.currentTarget.dataset.remoteTabsPageTitle
    if (pageTitle) {
      document.title = pageTitle
    }

    this.tabPanelTarget.style.opacity = "0.5"
    axios.get(e.currentTarget.href)
        .then(response => {
          this.tabPanelTarget.style.opacity = "1"
          this.tabPanelTarget.innerHTML = response.data
          BSN.initCallback()
        })

    this.tabTargets.forEach(el => {
      if (el === e.currentTarget) {
        el.classList.add(this.activeTabClass)
      } else {
        el.classList.remove(this.activeTabClass)
      }
    })
  }
}
